import http from '../api/http'
const qs = require('qs')

export default {
  // 简介
  frontAbout () {
    return http.apiForGet('/v1/front/about')
  },
  // 领导列表
  frontLeader (params) {
    return http.apiForGet('/v1/front/leader', params)
  },
  // 荣誉
  frontHonor () {
    return http.apiForGet('v1/front/honor')
  },
  // 联系我们
  frontContact () {
    return http.apiForGet('v1/front/contact')
  },
  // 类型列表
  frontCategory () {
    return http.apiForGet('v1/front/category')
  },
  // 文章列表
  frontArticle (params) {
    return http.apiForGet('v1/front/article', params)
  },
  // 文章列表
  frontArticleDetails (id) {
    return http.apiForGet('v1/front/article/' + id)
  },
  // 新增咨询
  frontConsult (data) {
    return http.apiForPost('v1/front/consult', qs.stringify(data))
  },
  // 业务介绍
  businessIntroductionDetail (params) {
    return http.apiForGet('v1/front/business-introduction/detail', params)
  },
  // 外链列表
  allTree () {
    return http.apiForGet('v1/front/outer-chain/all-tree')
  },
  // 外链列表
  frontBanner () {
    return http.apiForGet('v1/front/banner')
  }
}
