<template>
  <div class="home">
    <home-top />
    <div class="content-box">
      <img class="homeImg" :src="img" alt="" v-if="img">
      <div class="breadcrumb-box" v-if="isShow">
        <div class="breadcrumb-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="item in breadcrumbList" :key="item.name" :to="{ path: item.to }">{{ item.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <router-view />
    </div>
    <home-bottom />
  </div>
</template>

<script>
import { childrenView } from '@/router/index'
import homeTop from '@/components/homePage/homeTop.vue'
import HomeBottom from '@/components/homePage/homeBottom.vue'
export default {
  name: 'HomeView',
  components: { homeTop, HomeBottom },
  data () {
    return {
      isShow: false,
      img: '',
      breadcrumbList: []
    }
  },
  watch: {
    $route (val) {
      this.breadcrumbShow(val)
    }
  },
  methods: {
    breadcrumbShow (val) {
      this.isShow = val.meta.isShow
      this.img = val.meta.img
      if (this.isShow) {
        const breadcrumb = []
        const list = val.path.split('/')
        list.shift()
        const data = childrenView
        list.forEach(it => {
          data.forEach(el => {
            if (it === el.name) {
              breadcrumb.push({
                name: el.meta.title,
                to: el.path
              })
            }
          })
        })
        const categoryList = JSON.parse(localStorage.getItem('categoryList'))
        const numId = Number(localStorage.getItem('telentId'))
        let xname = ''
        categoryList.forEach(element => {
          if (element.id === numId) {
            xname = element.name
          }
        })
        if (val.name !== '集团概况' && val.name !== '集团动态' && val.name !== '服务产品' && val.name !== '信息公开' && val.name !== '党建文化') {
          breadcrumb.forEach(item => {
            const xxname = item.name
            item.name = xxname.replace(/人才招聘/g, xname)
          })
        }
        breadcrumb.forEach(el => {
          let num = true
          categoryList.forEach(ele => {
            if (el.name === ele.name && num) {
              num = false
              el.to = (el.to + '?id=' + ele.id)
            }
          })
        })
        breadcrumb[breadcrumb.length - 1].to = breadcrumb[breadcrumb.length - 1].to + location.search
        this.breadcrumbList = breadcrumb
      }
    }
  },
  created () {
    this.breadcrumbShow(this.$route)
  }
}
</script>

<style scoped lang="less">
.home{
  min-width: 1208px;
  .content-box{
    min-height: calc(100vh - 440px);
    .homeImg{
      display: block;
      width: 100%;
      max-width: 1920px;
      min-width: 1208px;
      margin: 0 auto;
    }
    .breadcrumb-box{
      width: 100%;
      border-bottom: 1px solid #E5E5E5;
      .breadcrumb-content{
        width: 1208px;
        padding: 0 18px;
        box-sizing: border-box;
        height: 80px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        /deep/.el-breadcrumb__inner, .is-link{
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
