<template>
  <div class="homeBottom">
    <div class="bottom-content">
      <div class="box">
        <div v-for="item in categoryList" :key="item.name" @click="open(item)">{{ item.name }}</div>
        <!-- <div @click="open('/groupProfile')">集团概况</div>
        <div @click="open('/groupDynamics')">集团动态</div>
        <div @click="open('/serviceProducts')">服务产品</div>
        <div @click="open('/publicInformation')">信息公开</div>
        <div @click="open('/partyBuildingCulture')">党建文化</div>
        <div @click="open('/talentRecruitment')">人才招聘</div> -->
      </div>
      <div class="details">
        <p>
          <span>地址：{{ contact.address }}</span>
          <span>邮编：{{ contact.postal_code }}</span>
        </p>
        <p>
          <span>电话：{{ contact.telphone }}</span>
        </p>
        <!-- <p>
          <span>传真：{{ contact.fax }}</span>
          <span>邮箱：{{ contact.website }}</span>
        </p> -->
      </div>
      <div class="local">
        <div v-if="bottompath.name" @click="handleClick(bottompath)">{{bottompath.name}}</div>
        <!-- <div>同级公司官网链接<i class="el-icon-caret-bottom"></i></div>
        <div>其他网站链接<i class="el-icon-caret-bottom"></i></div> -->
        <!-- <el-dropdown trigger="click" size="mini" split-button @click="handleClick(item.url)" v-for="item in allTree" :key="item.name">
          {{item.name}}
          <el-dropdown-menu slot="dropdown" v-for="it in item.childs" :key="it.name">
            <el-dropdown-item @click="handleClick(it.url)">{{it.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-dropdown v-for="item in allTree" :key="item.name">
          <el-button type="primary" @click="handleClick(item)">
            {{item.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="it in item.childs" :key="it.name">
              <span style="display:block;width:100%;height:100%;" @click="handleClick(it)">{{it.name}}</span>
            </el-dropdown-item>
            <!-- <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item>双皮奶</el-dropdown-item>
            <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="qrcode">
        <img src="@/assets/homePageImgs/切图 4.png" alt="">
        <div>浙江省人才发展集团官方公众号</div>
      </div>
    </div>
    <div class="bottom-bottom">版权所有：浙江省人才发展集团有限公司  技术支持：杭州戊辛科技有限公司  <span @click="jump">备案号：浙ICP备2022027736号</span></div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: 'homeBottom',
  components: {},
  data () {
    return {
      contact: {},
      bottompath: {},
      categoryList: [],
      allTree: []
    }
  },
  methods: {
    open (val) {
      let pushPath = ''
      if (val.name === '集团概况') {
        pushPath = '/groupProfile'
      }
      if (val.name === '集团动态') {
        pushPath = '/groupDynamics'
      }
      if (val.name === '服务产品') {
        pushPath = '/serviceProducts'
      }
      if (val.name === '信息公开') {
        pushPath = '/publicInformation'
      }
      if (val.name === '党建文化') {
        pushPath = '/partyBuildingCulture'
      }
      if (val.name === '人才招聘') {
        pushPath = '/talentRecruitment'
      }
      if (this.$route.path !== pushPath) {
        this.$router.push({
          path: pushPath,
          query: {
            id: val.id
          }
        })
      }
    },
    handleClick (val) {
      let pat = val.url
      if (pat.substr(0, 4) !== 'http') {
        if (pat.substr(0, 3) !== 'www') {
          pat = 'http://www.' + pat
        } else {
          pat = 'http://' + pat
        }
      }
      window.open(pat)
    },
    jump () {
      window.open('https://beian.miit.gov.cn')
    }
  },
  created () {
    api.frontCategory().then(res => {
      const data = res.data
      const result = []
      data.forEach(el => {
        if (el.parent_id === 0) {
          result.push(el)
        }
      })
      this.categoryList = result
    })
    api.frontContact().then(res => {
      this.contact = res.data
    })
    api.allTree().then(res => {
      const data = res.data
      data.forEach(el => {
        if (el.childs.length === 0) {
          this.bottompath = el
        } else {
          this.allTree.push(el)
        }
      })
    })
  }
}
</script>

<style scoped lang="less">
.homeBottom{
  width: 100%;
  height: 320px;
  background: #E0E8E9;
  position: relative;
  .bottom-content{
    width: 1208px;
    height: 280px;
    margin: 0 auto;
    position: relative;
    .box{
      height: 80px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(47,86,93,0.4);
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #6D8387;
      >div{
        margin-right: 90px;
        cursor: pointer;
      }
    }
    .details{
      margin-top: 18px;
      padding: 0 24px;
      p{
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #6D8387;
        line-height: 28px;
        span{
          margin-right: 25px;
        }
      }
    }
    .local{
      margin-top: 34px;
      padding: 0 24px;
      display: flex;
      >div{
        margin-right: 57px;
        width: 200px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #6D8387;
        background: rgba(77,148,159,0.2);
        position: relative;
        cursor: pointer;
      }
      /deep/.el-dropdown{
        width: 200px;
        line-height: 32px;
        text-align: center;
        background: rgba(77,148,159,0.2);
        position: relative;
        >button{
          border: none;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #6D8387;
          background-color: transparent;
        }
      }
    }
    .qrcode{
      position: absolute;
      bottom: 15px;
      right: 0;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #6D8387;
      img{
        width: 96px;
        height: 96px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .bottom-bottom{
    width: 100%;
    line-height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #2F565C;
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    span{
      cursor: pointer;
    }
  }
}
</style>
