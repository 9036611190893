<template>
  <div class="homeTop">
    <div class="top">
      <img @click="push('/homePage')" src="@/assets/homePageImgs/切图 2.png" alt="">
      <div class="top-right">
        <div
        :class="lineActive === index || clickLineActive === index ? 'divHover' : ''"
        v-for="(item, index) in categoryList"
        :key="item.name"
        @mouseout="divMouseout"
        @mouseover="divMouseover(item, index)"
        @click="open(item, index)">
          <img v-show="lineActive === index || clickLineActive === index" src="@/assets/homePageImgs/切图 1 (1).png" alt="">
          <span>{{ item.name }}</span>
          <div v-show="lineActive === index" class="erciMenu">
            <div :class="erciId === it.id ? 'erciborder' : ''" v-for="it in resultData" :key="it.name" @click.stop="erciopen(item, index, it)" :title="it.name">{{it.name}}</div>
          </div>
        </div>
        <!-- <div @click="open('/groupDynamics')">集团动态</div>
        <div @click="open('/serviceProducts')">服务产品</div>
        <div @click="open('/publicInformation')">信息公开</div>
        <div @click="open('/partyBuildingCulture')">党建文化</div>
        <div @click="open('/talentRecruitment')">人才招聘</div> -->
        <img @click="push('/globalSearch')" src="@/assets/homePageImgs/切图 3.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: 'homeTop',
  data () {
    return {
      clickLineActive: null,
      lineActive: null,
      erciId: null,
      categoryList: [],
      allData: [],
      resultData: []
    }
  },
  components: {},
  methods: {
    open (val, index, val2) {
      window.location.href = '#'
      console.log(val2)
      this.clickLineActive = index

      let pushPath = ''
      if (val.name === '集团概况') {
        pushPath = '/groupProfile'
      }
      if (val.name === '集团动态') {
        pushPath = '/groupDynamics'
      }
      if (val.name === '服务产品') {
        pushPath = '/serviceProducts'
      }
      if (val.name === '信息公开') {
        pushPath = '/publicInformation'
      }
      if (val.name === '党建文化') {
        pushPath = '/partyBuildingCulture'
      }
      if (val.name !== '集团概况' && val.name !== '集团动态' && val.name !== '服务产品' && val.name !== '信息公开' && val.name !== '党建文化') {
        pushPath = '/talentRecruitment'
      }
      // if (this.$route.path === pushPath) {

      // }
      // this.$router.push({
      //   path: pushPath,
      //   query: {
      //     id: val.id,
      //     childId: val2 ? val2.id : null
      //   }
      // })
      this.$router.push({
        path: '/reload',
        query: {
          id: val.id,
          childId: val2 ? val2.id : null,
          path: pushPath
        }
      })
      console.log(val2)
      if (!val2) {
        this.erciId = null
      }
      // if (Number(this.$route.query.id) !== val.id || this.$route.path !== pushPath) {
      //   // if (val2) {
      //   // } else {
      //   //   this.$router.push({
      //   //     path: pushPath,
      //   //     query: {
      //   //       id: val.id
      //   //     }
      //   //   })
      //   //   console.log(2)
      //   // }
      // }
    },
    erciopen (val, index, val2) {
      this.erciId = val2.id
      this.open(val, index, val2)
    },
    push (val) {
      this.erciId = null
      this.clickLineActive = null
      if (this.$route.path !== val) {
        this.$router.push({
          path: val
        })
      }
    },
    divMouseover (val, index) {
      const result = []
      this.allData.forEach(el => {
        if (el.parent_id === val.id) {
          result.push(el)
        }
      })
      this.resultData = result
      this.lineActive = index
    },
    divMouseout () {
      this.lineActive = null
    }
  },
  created() {
    api.frontCategory().then(res => {
      const data = res.data
      const result = []
      data.forEach(el => {
        if (el.parent_id === 0) {
          result.push(el)
        }
      })
      this.allData = res.data
      this.categoryList = result
      localStorage.setItem('categoryList', JSON.stringify(res.data))
    })
  }
}
</script>

<style scoped lang="less">
.homeTop{
  max-width: 1920px;
  min-width: 1208px;
  margin: 0 auto;
  position: relative;
  .top{
    width: 100%;
    height: 88px;
    padding: 0 80px;
    box-sizing: border-box;
    background: rgba(0,0,0,0.2);
    border: 1px solid rgba(255,255,255,0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 314px;
      height: 44px;
    }
    .top-right{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      >div{
        cursor: pointer;
        width: 104px;
        height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
          width: 24px;
          height: 24px;
        }
        .erciMenu{
          position: absolute;
          top: 88px;
          left: 0;
          width: 104px;
          background-color: rgba(255, 255, 255, 0.6);
          overflow: hidden;
          box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.04);
          >div{
            height: 60px;
            // margin: 8px;
            text-align: center;
            line-height: 60px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #3D3D3D;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .erciborder{
            background-color: rgba(77, 146, 157, 0.6);
          }
          >div:hover{
            background-color: rgba(77, 146, 157, .6);
          }
        }
      }
      .divHover{
        font-weight: bold;
      }
      >img{
        width: 36px;
        height: 24px;
        margin-left: 40px;
      }
    }
  }
}
</style>
