import axios from 'axios'

axios.defaults.timeout = 30000

// 线上
// const deafulBaseUrl = location.origin + '/index.php'
const deafulBaseUrl = location.origin + '/api' // 新线上
// 本地
// const deafulBaseUrl = location.origin

export default {
  apiForPost: function (url, params, baseURL) {
    axios.defaults.baseURL = deafulBaseUrl
    if (baseURL !== undefined) {
      axios.defaults.baseURL = baseURL
    }
    const p = new Promise((resolve, reject) => {
      axios.post(url, params, { 'Content-Type': 'application/x-www-form-urlencoded' }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
    return p
  },
  apiForGet: function (url, params, baseURL) {
    axios.defaults.baseURL = deafulBaseUrl
    if (baseURL !== undefined) {
      axios.defaults.baseURL = baseURL
    }
    const p = new Promise((resolve, reject) => {
      axios.get(url, {
        params: params
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch(err => {
        reject(err)
      })
    })
    return p
  },
  apiForPut: function (url, params, baseURL) {
    axios.defaults.baseURL = deafulBaseUrl
    if (baseURL !== undefined) {
      axios.defaults.baseURL = baseURL
    }
    const p = new Promise((resolve, reject) => {
      axios.post(url, params).then(response => {
        if (response.data.isSuccess === true) {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch(err => {
        reject(err)
      })
    })
    return p
  },
  apiForDel: function (url, params, baseURL) {
    axios.defaults.baseURL = deafulBaseUrl
    if (baseURL !== undefined) {
      axios.defaults.baseURL = baseURL
    }
    const p = new Promise((resolve, reject) => {
      axios.delete(url, {
        params: params
      }).then(response => {
        if (response.data.isSuccess === true) {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch(err => {
        reject(err)
      })
    })
    return p
  }
}

// axios.interceptors.request.use(function (response) {
//   const token = localStorage.getItem('tanToken')
//   if (token) {
//     response.headers['X-Litemall-Token'] = token
//   }
//   return response
// })
