import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

export const childrenView = [
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('../views/homePage/homePage.vue')
  },
  {
    path: '/groupProfile',
    name: 'groupProfile',
    meta: {
      title: '集团概况',
      img: require('@/assets/homePageImgs/切图 6(1).png')
    },
    component: () => import('../views/groupProfile/groupProfile.vue')
  },
  {
    path: '/groupProfile/allLeaders',
    name: 'allLeaders',
    meta: {
      title: '全部领导',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(7).png')
    },
    component: () => import('../views/groupProfile/components/allLeaders.vue')
  },
  {
    path: '/groupDynamics',
    name: 'groupDynamics',
    meta: {
      title: '集团动态',
      img: require('@/assets/homePageImgs/切图 6(7).png')
    },
    component: () => import('../views/groupDynamics/groupDynamics.vue')
  },
  {
    path: '/groupDynamics/dynamicDetails',
    name: 'dynamicDetails',
    meta: {
      title: '动态详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(7).png')
    },
    component: () => import('../views/groupDynamics/components/dynamicDetails.vue')
  },
  {
    path: '/serviceProducts',
    name: 'serviceProducts',
    meta: {
      title: '服务产品',
      img: require('@/assets/homePageImgs/切图 6(6).png')
    },
    component: () => import('../views/serviceProducts/serviceProducts.vue')
  },
  {
    path: '/serviceProducts/serviceProductsDetails',
    name: 'serviceProductsDetails',
    meta: {
      title: '服务产品详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(6).png')
    },
    component: () => import('../views/serviceProducts/components/serviceProductsDetails.vue')
  },
  {
    path: '/publicInformation',
    name: 'publicInformation',
    meta: {
      title: '信息公开',
      img: require('@/assets/homePageImgs/切图 6(5).png')
    },
    component: () => import('../views/publicInformation/publicInformation.vue')
  },
  {
    path: '/publicInformation/publicInformationDetails',
    name: 'publicInformationDetails',
    meta: {
      title: '信息公开详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(5).png')
    },
    component: () => import('../views/publicInformation/components/publicInformationDetails.vue')
  },
  {
    path: '/partyBuildingCulture',
    name: 'partyBuildingCulture',
    meta: {
      title: '党建文化',
      img: require('@/assets/homePageImgs/切图 6(4).png')
    },
    component: () => import('../views/partyBuildingCulture/partyBuildingCulture.vue')
  },
  {
    path: '/partyBuildingCulture/partyBuildingDetails',
    name: 'partyBuildingDetails',
    meta: {
      title: '党建文化详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(4).png')
    },
    component: () => import('../views/partyBuildingCulture/components/partyBuildingDetails.vue')
  },
  {
    path: '/talentRecruitment',
    name: 'talentRecruitment',
    meta: {
      title: '人才招聘',
      img: require('@/assets/homePageImgs/切图 6(3).png')
    },
    component: () => import('../views/talentRecruitment/talentRecruitment.vue')
  },
  {
    path: '/talentRecruitment/talentRecruitmentDetails',
    name: 'talentRecruitmentDetails',
    meta: {
      title: '人才招聘详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(3).png')
    },
    component: () => import('../views/talentRecruitment/components/talentRecruitmentDetails.vue')
  },
  {
    path: '/globalSearch',
    name: 'globalSearch',
    meta: {
      title: '全局搜索',
      img: require('@/assets/homePageImgs/切图 6(2).png')
    },
    component: () => import('../views/globalSearch/globalSearch.vue')
  },
  {
    path: '/globalSearch/searchDetails',
    name: 'searchDetails',
    meta: {
      title: '文章详情',
      isShow: true,
      img: require('@/assets/homePageImgs/切图 6(2).png')
    },
    component: () => import('../views/globalSearch/components/searchDetails.vue')
  }
]

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/homePage',
    children: childrenView
  },
  {
    path: '/reload',
    component: () => import('@/views/pageReload')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
